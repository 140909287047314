<template>
  <div class="card__box">
    <div class="card__wrapper" :class="{'card__wrapper--active': isOurs}">
      <div class="card__top">
        <div class="card__row">
          <div class="card__price">$ {{ obj.price }}</div>
          <div class="card__cell">
            <div class="card__button button__icon button__icon--border" @click.stop.prevent="favorites">
              <i class="i-bookmark" :class="{'i-bookmark--blue': obj.isSaved}"></i>
            </div>
            <div class="card__button button__icon button__icon--border" @click.stop.prevent>
              <Multiselect
                class="mSelect-dropdown"
                :options="list"
                :searchable="false"
                :show-labels="false"
                :reset-after="true"
                :value="item"
                placeholder=""
                open-direction="bottom"
                @select="actions"
              />
            </div>
            <div v-if="selectionActive" class="card__button button__icon button__icon--border" @click.stop.prevent="select">
              <i :class="{'ri-checkbox-fill': obj.isSelected, 'ri-checkbox-blank-line': !obj.isSelected}"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="card__middle">
        <div class="card__field card__field--dark">
          <div class="card__street">{{ obj.street }}, {{ obj.houseNo }}</div>
          <div class="card__district">
            <i class="i-location i-location--blue"></i>
            <span class="card__span" v-if="obj.districtName">{{ obj.districtName }}</span>
            <span class="card__span" v-else>{{ obj.settlement }}</span>
          </div>
        </div>
        <div class="card__row card__tags">
          <div class="card__tag card__tag--yellow">
            <span class="card__span">{{ obj.motivation }}</span>
          </div>
          <div class="card__tag card__tag--green">
            <span class="card__span">{{ obj.propertyStatus | propertyStatus }}</span>
          </div>
          <div class="card__tag card__tag--red">
            <span class="card__span">{{ obj.contract }}</span>
          </div>
        </div>
      </div>
      <div class="card__bottom">
        <div class="card__id">ID {{ obj.id }}</div>
        <Relation v-for="(rel, i) in obj.relations" :relation="rel" type="card-tile" :key="i"/>
        <div class="card__icoWrapper">
          <div class="card__agent" :class="{'card__agent--blocked': obj.isResponsibleBlocked}">
            <i class="i-employees card__agent-icon"></i>
            <span class="card__agent-text">{{ obj.responsibleName }}</span>
          </div>
          <i class="i-image card__photo" :class="{'card__photo--blue': obj.photos.length}"></i>
        </div>
      </div>
      <div class="card__fields">
        <div class="card__field">
          <div class="card__text">
            <span>Кімнат</span>
            <span v-if="obj.type === 'house'">/Поверхів</span>
          </div>
          <div class="card__text">
            <span>{{ obj.noOfRooms }}</span>
            <span v-if="obj.type === 'house'">/{{ obj.floors }}</span>
          </div>
        </div>
        <div class="card__field">
          <div class="card__text">
            <span v-if="obj.type === 'commerce'">Призначення</span>
            <span v-else>Вид/Тип</span>
          </div>
          <div v-if="obj.type === 'apartment'" class="card__text">
            {{ obj.material | apartmentMaterial }}/{{ obj.buildingType | apartmentType }}
          </div>
          <div v-if="obj.type === 'house'" class="card__text">
            {{ obj.material | buildingMaterial }}/{{ obj.buildingType | buildingType }}
          </div>
          <div v-if="obj.type === 'commerce'" class="card__text">{{ obj.buildingType | commerceType }}</div>
        </div>
        <div v-if="obj.type !== 'house'" class="card__field">
          <div class="card__text">Поверх</div>
          <div class="card__text">{{ obj.floor }}/{{ obj.floors }}</div>
        </div>
        <div class="card__field">
          <div class="card__text">Площа м²</div>
          <div class="card__text">{{ obj.squareTotal }}/{{ obj.squareLiving }}/{{ obj.squareKitchen }}</div>
        </div>
        <div v-if="obj.type === 'house'" class="card__field">
          <div class="card__text">Площа ділянки</div>
          <div class="card__text">{{ obj.squareLand }}</div>
        </div>
        <div class="card__field">
          <div class="card__text">
            <span>Ремонт/</span>
            <span v-if="obj.type !== 'house'">Авт. опал.</span>
            <span v-else>Гараж</span>
          </div>
          <div class="card__text">
            <span class="card__span" v-if="obj.renovated">Є</span>
            <span class="card__span" v-else>Немає</span>
            <span class="card__span">/</span>
            <span v-if="obj.type === 'house'">
              <span class="card__span" v-if="obj.garage">Є</span>
              <span class="card__span" v-else>Немає</span>
            </span>
            <span v-else>
              <span class="card__span">{{ obj.autonomousHeatType }}</span>
            </span>
          </div>
        </div>
        <div v-if="obj.type === 'house'" class="card__field">
          <div class="card__text">Рік побудови</div>
          <div class="card__text">{{ obj.builtAt }}</div>
        </div>
        <div class="card__field">
          <div class="card__text">Останній Дзвінок</div>
          <div class="card__text">
            <i class="i-calendar"></i>
            <span v-if="obj.lastCallAt">{{ moment(obj.lastCallAt).format('DD.MM.YYYY') }}</span>
            <span v-else>-</span>
          </div>
        </div>
        <div class="card__field">
          <div class="card__text">Зміна ціни</div>
          <div class="card__text">
            <i class="i-calendar"></i>
            <span class="card__span">{{ moment(obj.priceUpdatedAt).format('DD.MM.YYYY') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Archive from '@/components/ModalArchive'
import Edit from '../Upload'
import Relation from '@/components/Relation'
import moment from 'moment'

export default {
  name: 'ObjectCard',
  props: {
    obj: {type: Object, default: null}
  },
  components: {
    Multiselect,
    Relation,
  },
  computed: {
    selectionActive() {
      switch (this.obj.type) {
        case 'apartment':
          return this.$store.getters['objects/apartments/selectionActive']
        case 'house':
          return this.$store.getters['objects/houses/selectionActive']
        case 'commerce':
          return this.$store.getters['objects/commerce/selectionActive']
        default:
          return false
      }
    }
  },
  data: () => ({
    list: ['Редагувати', 'В Архів'],
    item: '',
    isOurs: false,
    moment
  }),
  created() {
    const source = this.$store.getters.constants.PROPERTY_SOURCES[3].children.map(p => p.id)
    this.isOurs = !source.some(prop => prop === this.obj.source)
  },
  methods: {
    async favorites() {
      const id = this.obj.id
      const isSaved = this.obj.isSaved
      let path = ''
      switch (this.obj.type) {
        case 'apartment':
          path = 'objects/apartments/favorites'
          break
        case 'house':
          path = 'objects/houses/favorites'
          break
        case 'commerce':
          path = 'objects/commerce/favorites'
          break
      }
      try {
        await this.$store.dispatch(path, {id, isSaved})
        this.obj.isSaved = !this.obj.isSaved
      } catch (err) {}
    },
    async select() {
      const id = this.obj.id
      let path = ''
      switch (this.obj.type) {
        case 'apartment':
          path = 'objects/apartments/selectObject'
          break
        case 'house':
          path = 'objects/houses/selectObject'
          break
        case 'commerce':
          path = 'objects/commerce/selectObject'
          break
      }
      this.$store.commit(path, {id, isSelected: !this.obj.isSelected})
    },
    actions(option) {
      if (option === 'Редагувати') {
        this.$modal.display(Edit, {info: this.obj, type: this.obj.type}, {name: 'UploadObject', clickToClose: false})
      } else if (option === 'В Архів') {
        this.$modal.display(
          Archive,
          {info: this.obj, entity: 'objects', action: 'archive'},
          {transition: 'none', classes: 'modalArchive', width: '500px', height: '350px', name: 'ModalArchive'}
        )
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .card__button {
    .ri-checkbox-fill, .ri-checkbox-blank-line {
      font-size: 20px;
    }
  }
</style>